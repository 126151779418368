:root {
  --white: #ffffff;
  --charcoal: #2e2c33;
  --dark_grey: #575757;
  --light_grey: #d7d7d7;
  --green: #1b5238;
  --alert_red: #ff0404;
  --pink: #ffc7f9;
  --orange: #ff5e1f;
  --blue: #69d9ff;

  --qualcomm_white: #ffffff;
  --qualcomm_blue: #3253dc;
  --qualcomm_light_blue: #7ba0ff;
  --qualcomm_light_blue_1: #94b2ff;
  --qualcomm_light_blue_2: #b0c7ff;
  --qualcomm_teal: #4ab7ca;
  --qualcomm_teal_1: #6ec5d5;
  --qualcomm_teal_2: #8bd1dd;
  --qualcomm_gunmetal: #4a5a75;
  --qualcomm_gunmetal_1: #677790;
  --qualcomm_gunmetal_2: #8393aa;
  --qualcomm_midnight: #0b2742;
  --qualcomm_nickel: #e9ebee;
  --qualcomm_red: #e71324;

  --qualcomm_text_primary: #000000f2;
  --qualcomm_text_secondary: #0000008c;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}

.vertical-align {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.unitdot-big {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sparkline {
  display: inline-block;
  height: 5px;
}

.idbox {
  color: var(--dark_grey);
  border-radius: 16px;
  border-color: rgb(214, 214, 214);
  border-style: solid;
  border-width: 1px;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 90%;
  background-color: rgb(249, 249, 249);
}

.scrollable {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.state-icon {
  width: 27px;
  min-width: 27px;
  height: 27px;
  min-height: 27px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.state-icon-inner {
  width: 27px;
  height: 27px;
  margin: 0;
  padding: 0;
  display: flex;
  line-height: 100%;
  justify-content: center;
  align-items: center;
}

.CircularProgressbar-trail {
  stroke-width: 10px;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.vertical-align-elements,
.vertical-align-elements-padded {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.vertical-align-elements > * {
  display: flex;
}

.vertical-align-elements-padded > p,
.vertical-align-elements-padded > span {
  margin-left: 8px;
}

.failure-reason pre {
  font-size: 16px;
}

code {
  font-size: 14px;
  color: black;
  background-color: rgba(175, 184, 193, 0.2);

  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  padding-bottom: 2.7px;
  padding-left: 5.4px;
  padding-right: 5.4px;
  padding-top: 2.7px;
}

pre {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: black;
}

a {
  color: var(--qualcomm_blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.main-nav {
  background-color: var(--qualcomm_white);
  border-bottom: 1px solid #ccc;
  display: flex;
  line-height: 4em;
  height: 4em;
  justify-content: space-between;
  box-shadow: 0 0px 15px 0 rgb(0 0 0 / 7%);
  align-items: center;
}

.main-nav > div {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar--logo a {
  display: flex;
  align-items: center;
  color: var(--qualcomm_gunmetal);
  text-decoration: none;
  font-family: 'Qualcomm Next';
  line-height: 0;
  margin: 0em;
  padding: 0px;
}

.navbar--logo a img {
  margin: 0.5em;
  padding: 0px;
}

.main-nav h3 {
  margin: 0;
  padding-left: 1rem;
  font-size: 3em;
  color: #7f2fe2;
  vertical-align: middle;
}

.maintenance-banner a {
  text-decoration: underline;
}

.nav--links {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  gap: 0.4em;
}

.nav-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 1em;

  /*
   * This is so that the ugly footer is always past the page boundary. Yes, there's always a scroll
   * bar, but wow our footer is ugly. It's like reading a legal document.
   */
  min-height: 100vh;
}

.MuiTableCell-body > a:hover {
  color: var(--qualcomm_blue);
}

tbody.detailed-metrics > tr:last-child > td {
  border: 0;
}

.right-aligned-metric {
  display: block;
  text-align: right;
}

.deprecated-metric {
  color: var(--orange);
}

.app-footer {
  margin-top: auto;
  max-width: 1680px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 1440px) {
  .app-footer {
    padding-left: 130px;
    padding-right: 130px;
  }
}

@media (min-width: 550px) {
  .app-footer {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.app-footer-logo {
  border-top: 1px solid #bbb;
  padding-top: 32px;
}

.app-footer nav {
  padding-top: 16px;
  padding-bottom: 16px;
}

.app-footer nav ul {
  display: flex;
  column-gap: 16px;
  line-height: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: baseline;
}

.app-footer nav a,
.app-footer nav button {
  color: var(--qualcomm_text_secondary);
  font-size: 12px;
  line-height: 16px;
}

.app-footer nav button {
  text-decoration: none;
}

.app-footer nav button:hover {
  text-decoration: underline;
}

.app-footer .copyright {
  color: #bbc0c8;
  font-size: 12px;
}

.app-footer .copyright p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
}
